import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ChallengeCard from './ChallengeCard';

const ChallengesList = ({ challenges, trivia, contest }) => (
  <>
    <Row id="nn-challenges-wrapper" className="my-4">
      {challenges.map(challenge => (
        <Col key={challenge.id} xs={12} sm={6} md={4} lg={3}>
          <ChallengeCard item={challenge} trivia={trivia} contest={contest} />
        </Col>
      ))}
    </Row>
  </>
);

ChallengesList.propTypes = {
  challenges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
      reward: PropTypes.number,
      imageUrl: PropTypes.string,
      summary: PropTypes.string
    })
  ).isRequired
};

export default ChallengesList;
