import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './styles.scss';

const NewsCard = ({ item, newsType, prizeType }) => {
  const redirectRoute = () => {
    if (newsType) return `/news/${item.id}`;
    if (prizeType) return `/prize/${item.id}=prize`;
  };

  return (
    <div key={item.id} className="card border-light news-container my-3">
      <img src={item.fileInfo.fileUrl} className="card-img-top" alt="..." />
      <div className="card-body">
        <h5 className="card-title">{item.title}</h5>
        <div className="card-description">
          <p className="card-body-text">{item.lede}</p>
        </div>
      </div>
      <div className="redeem-btn">
        <Button
          block
          as={Link}
          to={redirectRoute()}
          className="btn-primary btn-circle btn-xl nn-challenge-link"
        >
          VER MÁS...
        </Button>
      </div>
    </div>
  );
};

NewsCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    fileInfo: PropTypes.shape({
      fileUrl: PropTypes.string,
      id: PropTypes.number
    }).isRequired,
    specific: PropTypes.string,
    id: PropTypes.number.isRequired
  }).isRequired
};

export default NewsCard;
