import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles.scss';

const Slides = ({ items, specific, carouselStyle }) => (
  <Carousel
    className={specific}
    controls={items.length > 1}
    indicators={items.length > 1}
    style={carouselStyle}
  >
    {items.map(item => (
      <Carousel.Item key={`slide-${item.id}`}>
        <Link to={item.link}>
          <img src={item.fileInfo.fileUrl} alt={item.caption} />
        </Link>
        <Carousel.Caption>
          {item.caption && <h3>{item.caption}</h3>}
        </Carousel.Caption>
      </Carousel.Item>
    ))}
  </Carousel>
);

Slides.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      fileInfo: PropTypes.shape({
        id: PropTypes.number,
        fileUrl: PropTypes.string
      }).isRequired,
      specific: PropTypes.string,
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  carouselStyle: PropTypes.shape()
};

Slides.defaultProps = {
  carouselStyle: {}
};
export default Slides;
