import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useLocation } from 'react-router-dom';
import { BackBtn, Slides, Loader } from '../../components';
import { indexNewsRequest, showNewsRequest } from '../../requests/news';
import NewsCard from './NewsCard';

const NewsShow = props => {
  const {
    settings: { hideMoreNews }
  } = useSelector(state => state.utils);
  const location = useLocation();
  const isPrizePath = location.pathname.split('=')[1] === 'prize';

  const [onRequest, setOnRequest] = useState(false);
  const [news, setNews] = useState({
    id: '',
    title: '',
    bodyContent: '',
    publicationDate: '',
    fileInfo: {}
  });
  const [moreNews, setMoreNews] = useState([]);

  const redirectBackRoute = () => {
    if (isPrizePath) {
      return '/prize';
    }
    return '/news';
  };

  const handleSuccessShow = response => {
    const _news = camelCaseRecursive(response.data);
    setNews(_news);
  };

  const displayContent = htmlContent => {
    if (htmlContent) {
      return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    }
    return <h4>404</h4>;
  };

  const renderMoreNews = () =>
    moreNews.map(n => (
      <Col md={6}>
        <NewsCard item={n} newsType={!isPrizePath} prizeType={isPrizePath} />
      </Col>
    ));

  useEffect(() => {
    const { match, dispatch } = props;
    const newsId = match.params.id;
    setOnRequest(true);
    showNewsRequest(newsId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });

    indexNewsRequest({
      dispatch,
      params: {
        news_type: isPrizePath ? 1 : 0
      },
      successCallback: result => {
        const _moreNews = camelCaseRecursive(result.data.data);
        setMoreNews(_moreNews.filter(n => n.id !== Number(newsId)).slice(0, 2));
        setOnRequest(false);
      }
    });
  }, [props]);

  return onRequest ? (
    <Loader />
  ) : (
    <Container>
      <Row className="my-3">
        <Col md={2}>
          <BackBtn route={redirectBackRoute()} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="screen-title">{news.title}</h2>
          <p>{news.lede}</p>
        </Col>
        <Col md={{ span: 4 }} style={{ textAlign: 'end' }}>
          {/* <p>{news.publicationDate}</p> */}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={{ span: 12 }}>
          <Slides
            items={[news]}
            carouselStyle={{
              height: 250
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="news-paragraph">{displayContent(news.bodyContent)}</Col>
      </Row>
      {hideMoreNews === 1 ? null : (
        <>
          {moreNews.length > 0 && (
            <>
              <Col className="d-flex my-5">
                <Col className="d-flex justify-content-center">
                  <h4 className="screen-title">
                    Otras noticias que pueden interesarte
                  </h4>
                </Col>
              </Col>
              <Row>{renderMoreNews()}</Row>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default connect()(NewsShow);
