import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatToPoints } from '../../services/utils';

const ChallengeCard = ({ item, trivia, contest }) => {
  const redirectRoute = () => {
    if (!trivia && !contest) return `/challenges/${item.id}`;
    if (trivia) return `/trivia/${item.id}=trivia`;
    if (contest) return `/contest/${item.id}=contest`;
  };

  const buttonName = () => {
    if (trivia) return 'VER TRIVIA';
    if (contest) return 'VER CONCURSO';

    return 'VER DESAFÍO';
  };

  const {
    settings: { nameWord }
  } = useSelector(state => state.utils);
  return (
    <div key={item.id} className="card border-light product-container my-3">
      <img src={item.imageUrl} className="card-img-top" alt="..." />
      <div className="card-body">
        <p className="card-title">{item.title}</p>
        <div className="card-description">
          <p className="card-body-text card-description">{item.summary}</p>
        </div>
        <div className="d-flex flex-wrap">
          <div className="mr-2 my-auto nn-circle" />
          <p className="my-2 reward-text">
            {formatToPoints(item.reward)} {nameWord}
          </p>
        </div>
      </div>
      <div className="redeem-btn">
        <Button
          block
          as={Link}
          to={redirectRoute()}
          className="btn-primary btn-circle btn-xl nn-challenge-link"
        >
          {buttonName()}
        </Button>
      </div>
    </div>
  );
};
ChallengeCard.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    fileInfo: PropTypes.shape({
      fileUrl: PropTypes.string,
      id: PropTypes.number
    }).isRequired,
    productCategory: PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string
    }),
    specific: PropTypes.string,
    id: PropTypes.number.isRequired
  }).isRequired
};

export default ChallengeCard;
