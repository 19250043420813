import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import NewsCard from './NewsCard';

const NewsList = ({ newsList, newsType, prizeType }) => (
  <>
    <Row id="nn-news-wrapper" className="my-4">
      {newsList.map(news => (
        <Col xs={12} sm={6} md={4} key={news.id}>
          <NewsCard item={news} newsType={newsType} prizeType={prizeType} />
        </Col>
      ))}
    </Row>
  </>
);

NewsList.propTypes = {
  newsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      bodyContent: PropTypes.text,
      publicationDate: PropTypes.string,
      fileInfo: PropTypes.shape({
        id: PropTypes.number,
        fileUrl: PropTypes.string
      }).isRequired
    })
  ).isRequired
};

export default NewsList;
