import React from 'react';
import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = ({
  toggled,
  handleToggleSidebar,
  unreadChallenges,
  unreadNews
}) => {
  const url = useLocation();
  const {
    settings: {
      showTriviasLink,
      showContestsLink,
      hideProductsLink,
      showPrizeLink,
      hideProfile,
      termsLink
    }
  } = useSelector(state => state.utils);
  return (
    <ProSidebar
      onToggle={handleToggleSidebar}
      breakPoint="md"
      toggled={toggled}
      width={226}
    >
      <>
        <Menu iconShape="square">
          <MenuItem
            active={url.pathname.includes('/home')}
            suffix={<Badge variant="primary">{unreadChallenges || null}</Badge>}
          >
            Inicio
            <Link to="/home" />
          </MenuItem>
          <MenuItem
            active={url.pathname.includes('/challenges')}
            suffix={<Badge variant="primary">{unreadChallenges || null}</Badge>}
          >
            Desafíos
            <Link to="/challenges" />
          </MenuItem>
          {showTriviasLink === 1 && (
            <MenuItem
              active={url.pathname.includes('/trivia')}
              suffix={
                <Badge variant="primary">{unreadChallenges || null}</Badge>
              }
            >
              Trivias
              <Link to="/trivia" />
            </MenuItem>
          )}
          {showContestsLink === 1 && (
            <MenuItem
              active={url.pathname.includes('/contest')}
              suffix={
                <Badge variant="primary">{unreadChallenges || null}</Badge>
              }
            >
              Concursos <br /> Relámpagos
              <Link to="/contest" />
            </MenuItem>
          )}
          {hideProductsLink === 1 ? null : (
            <MenuItem active={url.pathname.includes('/products')}>
              Premios
              <Link to="/products" />
            </MenuItem>
          )}
          <MenuItem
            active={url.pathname.includes('/news')}
            suffix={<Badge variant="primary">{unreadNews || null}</Badge>}
          >
            Noticias
            <Link to="/news" />
          </MenuItem>
          {showPrizeLink === 1 && (
            <MenuItem
              active={url.pathname.includes('/prize')}
              suffix={<Badge variant="primary">{unreadNews || null}</Badge>}
            >
              Premios
              <Link to="/prize" />
            </MenuItem>
          )}
          <MenuItem active={url.pathname.includes('/ranking')}>
            Ranking
            <Link to="/ranking" />
          </MenuItem>
          {termsLink && (
            <MenuItem active={url.pathname.includes(termsLink)}>
              Bases
              <Link to={termsLink} />
            </MenuItem>
          )}
          {hideProfile === 1 ? null : (
            <MenuItem active={url.pathname.includes('/profile')}>
              Perfil
              <Link to="/profile" />
            </MenuItem>
          )}
        </Menu>
      </>
    </ProSidebar>
  );
};

export default Sidebar;
