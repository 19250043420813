import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import './styles.scss';
import { useSelector } from 'react-redux';
import { formatToPoints } from '../../services/utils';
import baseColors from '../../assets/stylesheets/base_colors';

const ProductCard = ({ item }) => {
  const {
    settings: { nameWord }
  } = useSelector(state => state.utils);
  return (
    <div
      key={item.id}
      className="card border-light product-container card-product my-3"
    >
      {/* <div className="container-img" ref={ref} style={{height: imgHeight }}> */}
      <img src={item.fileInfo.fileUrl} className="card-img-top" alt="..." />
      {/* </div> */}

      <div
        className="text-center"
        style={{
          backgroundColor: item.productCategory && item.productCategory.color
        }}
      >
        {item.productCategory ? item.productCategory.name : 'Categoría'}
      </div>
      <div className="card-body">
        <div className="card-title">{item.name}</div>
        <div className="card-body-text d-flex flex-wrap">
          <div className="mr-2 my-auto nn-circle" />
          <p
            className="my-2 reward-text"
            style={{ color: baseColors.colorTextPointsProducts }}
          >
            {formatToPoints(item.price)} {nameWord}
          </p>
        </div>
      </div>
      <div className="redeem-btn">
        <Button
          block
          className="btn-primary btn-circle btn-xl nn-product-link"
          href={`/products/${item.id}`}
        >
          VER MÁS
        </Button>
      </div>
    </div>
  );
};
ProductCard.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    fileInfo: PropTypes.shape({
      fileUrl: PropTypes.string,
      id: PropTypes.number
    }).isRequired,
    productCategory: PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string
    }),
    specific: PropTypes.string,
    id: PropTypes.number.isRequired
  }).isRequired
};

export default ProductCard;
