const baseColors = {
  primary: '#b5313a',
  secondary: '#c1d700',
  red: '#dc3545',
  primaryBg: '#f2e6dd',

  // datatable
  bgDatatable: '#fff',
  colorDatatable: 'null',

  // select
  bgSelect: 'null',
  bgMultiValueSelect: '#ffc800',
  colorMultiValueSelect: 'b5313a',
  hoverBgMultiValueRemoveSelect: '#ffc800',
  hoverTextMultiValueRemoveSelect: '#b5313a',

  // text points product
  colorTextPointsProducts: '#042730',

  gray100: '#f8f9fa',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#6c757d',
  gray700: '#495057',
  gray800: '#343a40',
  gray900: '#282828'
};

export default baseColors;
